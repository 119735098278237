<template>
  <q-input dense :lazy-rules="lazyRules" outlined :input-class="inputClass" :class="clase"
           v-model="displayValue" @blur="isInputActive = false" @focus="isInputActive = true"
           :rules="rules" maxlength="12">
    <template v-slot:prepend v-if="isMoney"><q-icon size="12px" name="attach_money"/></template>
    <template v-slot:append v-if="append"><div class="fs-11 q-pt-xs">{{appendText}}</div></template>
  </q-input>
</template>

<script>
  export default {
    name: "inputMoney",
    props: {
      value: Number,
      rules: {type: Array, default: () => {return []}},
      inputClass: String,
      clase: String,
      lazyRules: String,
      isMoney: {type: Boolean, default: true},
      append: {type: Boolean, default: false},
      appendText: {type: String, default: ""},
    },
    data() {
      return {
        isInputActive: false,
      }
    },
    computed: {
      displayValue: {
        get: function() {
          if (this.isInputActive) {
            // Cursor is inside the input field. unformat display value for user
            return this.value.toString();
          } else {
            // User is not modifying now. Format display value for user interface
            let money = parseFloat(this.value.toString());
            return isNaN(money) ? 0 : money.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
          }
        },
        set: function(modifiedValue) {
          // Recalculate value after ignoring "$" and "," in user input
          let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, ""));
          // Ensure that it is not NaN
          if (isNaN(newValue)) {
            newValue = 0;
          }
          // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
          // $emit the event so that parent component gets it
          this.$emit('input', newValue);
        }
      }
    }
  }
</script>