import axios from "axios";
import router from "../../router";


const CancelToken = axios.CancelToken;
let source = CancelToken.source();
const http = axios.create({
  baseURL: localStorage.apiUrl,
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  responseType: 'json',
});

http.interceptors.request.use(
  config => {
    let apiURL = localStorage.getItem("apiUrl");
    if (apiURL) {
      http.defaults.baseURL = localStorage.getItem("apiUrl");
      config.baseURL = localStorage.getItem("apiUrl");
    }
    config.metadata = { startTime: new Date()}
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(response => {
  if (response.data.codigo === 403) {
    source.cancel();
    localStorage.clear();
    router.push({name: 'login'});
    return Promise.reject(new axios.Cancel('.'));
  }
  response.responseTime = Math.abs((new Date().getTime() - response.config.metadata.startTime.getTime()) / 1000);
  return response;
}, (error) => {return Promise.reject(error.response);});

export default http;
