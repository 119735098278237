<template>
  <div>
    <q-dialog @show="onShow" @hide="onClose" v-model="model" persistent>
      <q-card :class="globalClases" class="text-grey-system q-px-lg">
        <q-card-section class="row">
          <div class="fs-17
           fs-w-600 col-11" v-text="titulo"/>
          <div class="col-1 text-right">
            <q-btn icon="mdi-close" v-if="!closeHidden" dense flat rounded v-close-popup />
          </div>
          <q-separator class="q-mt-sm"/>
        </q-card-section>
        <q-card-section :class="bodyClass">
          <q-banner class="bg-orange-1 q-mb-lg border-orange-4" v-if="alertWarning" rounded>
            <template v-slot:avatar><q-icon name="las la-exclamation-triangle" color="orange-8 q-mt-xs" size="26px" /></template>
            <div class="fs-12 text-orange-8">
              <slot name="bannerMensaje">{{mensajeBanner || 'Esta acción no se puede deshacer.'}}</slot>
            </div>
          </q-banner>
          <q-banner class="bg-green-1 border-green-4 q-mb-lg" v-if="alertSuccess" rounded>
            <template v-slot:avatar><q-icon name="las la-check" color="green-8 q-mt-xs" size="26px" /></template>
            <div class="fs-12 text-green-8">
              <slot name="bannerMensaje">{{mensajeBanner || 'Proceso finalizado correctamente.'}}</slot>
            </div>
          </q-banner>
          <q-banner class="bg-blue-1 border-blue-4 q-mb-lg" v-if="alertInformation" rounded>
            <template v-slot:avatar><q-icon name="las la-info-circle" color="blue-8 q-mt-xs" size="26px" /></template>
            <div class="fs-12 text-blue-8">
              <slot name="bannerMensaje">{{mensajeBanner || 'No se muestran todos los registros existentes.'}}</slot>
            </div>
          </q-banner>
          <slot name="body">
            <div>Esto es un body de modal</div>
          </slot>
        </q-card-section>
        <template v-if="!footerHidden">
          <q-card-actions :align="alineacion" :class="footerClass" class="q-pb-md">
            <slot name="footer">
              <q-btn flat label="OK" v-close-popup/>
            </slot>
          </q-card-actions>
        </template>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  name: "modal",
  props: {
    model: Boolean,
    globalClases: String,
    titulo: String,
    mensajeBanner: String,
    bodyClass: String,
    footerClass: String,
    alineacion: {type:String,default:"right"},
    footerHidden: {type: Boolean, default: false},
    closeHidden: {type: Boolean, default: false},
    alertWarning: {type: Boolean, default: false},
    alertSuccess: {type: Boolean, default: false},
    alertInformation: {type: Boolean, default: false},
  },
  methods: {
    onClose() {
      this.$emit("on-close");
    },
    onShow() {
      this.$emit("on-show");
    }
  }
}
</script>
