import ValidarPermisoService from '../Services/ValidarPermisoService';
import permisos from '../permisos';

export default [
  { path: "/login", component: () => import("../views/login"), name: "login" },
  {
    path: "/",
    component: () => import("../components/layouts/Template"),
    children: [
      { path: "home", component: () => import("../views/home"), name: "home"},
      //Usuarios
      { path:"usuarios", component: () => import("../views/usuarios/usuariosGestor.vue"), name: "usuariosGestor", meta:{ requiresAuth : true}},
      { path:"usuarios/agregar", component: () => import("../views/usuarios/usuarioAgregar.vue"), name: "usuarioAgregar", meta:{ requiresAuth : true}},
      { path:"usuarios/editar/:id", component: () => import("../views/usuarios/usuarioEditar.vue"), name: "usuarioEditar", meta:{ requiresAuth : true}},
    ]
  }
];

