/* globals __ENV */
import Vue from 'vue';
import PermisoService from './Services/PermisoService';

const permisoService = new PermisoService();

export default {

/*************************/
/**PERMISOS PARA USUARIOS**/
/***********************/
USUARIOS_VISUALIZAR : 'Bhdirectorio.Configuraciones.Usuarios.ListadoVisualizar',
USUARIOS_AGREGAR : 'Bhdirectorio.Configuraciones.Usuarios.Agregar',

  existePermiso: async (permiso) => {
    let permisoBol = false;

    await permisoService.existePermiso({permiso: permiso}).then(
      resp => {
        permisoBol = resp;
      }
    );
    
    return permisoBol;
  }
}
