<template>
  <div>
    <q-dialog @show="onShow" @hide="onClose" v-model="model" persistent>
      <q-card :class="globalClases" class="text-grey-system">
        <q-card-section class="row" v-if="withTitle">
          <div class="text-h5 col-11" v-text="titulo"/>
          <div class="col-1 text-right">
            <q-btn icon="mdi-close" dense flat rounded v-close-popup />
          </div>
        </q-card-section>
        <q-card-section :class="bodyClass">
          <q-icon name="mdi-checkbox-marked-circle-outline" color="positive" size="50px" class="q-mb-sm" />
          <div class="text-h6" v-text="mensaje || 'Registro agregado correctamente'"/>
        </q-card-section>
        <q-separator />
        <q-card-actions :align="alineacion" :class="footerClass">
          <slot name="footer">
            <q-btn flat label="OK" v-close-popup/>
          </slot>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
  export default {
    name: "modal",
    props: {
      model: Boolean,
      globalClases: String,
      titulo: String,
      bodyClass: String,
      footerClass: String,
      alineacion: {type:String,default:"right"},
      withTitle: { type: Boolean, default:false},
      mensaje: String
    },
    methods: {
      onClose() {
        this.$emit("on-close");
      },
      onShow() {
        this.$emit("on-show");
      }
    }
  }
</script>
