export default {
  getApi() {
    let isProducction = process.env.NODE_ENV === "production";
    let pathBase = isProducction ? process.env.VUE_APP_BASE_URL : '/';
    return new Promise(resolve => {
      if (isProducction) {
        fetch(pathBase + 'properties.json')
          .then(response => response.json())
          .then(data => {
            //localStorage.clear();
            localStorage.setItem("apiUrl", data.API_URL);
            localStorage.setItem("amazonS3", data.AMAZON_S3);
            localStorage.setItem("frontVersion", data.FRONT_VERSION);
          }).catch(err => console.log(err));
      } else {
        localStorage.setItem("apiUrl", process.env.VUE_APP_BASE_API);
        localStorage.setItem("amazonS3", process.env.VUE_APP_S3_PATH);
        localStorage.setItem("frontVersion", process.env.VUE_APP_FRONT_VERSION);
      }
      resolve();
    });
  }
}
