<template>
  <div class="w100p row justify-center items-center q-pt-xl">
    <div class="col-xs-8 col-lg-6">
      <div class="text-center">
        <q-img width="500px" alt="permisoError" src="https://www.pinclipart.com/picdir/middle/342-3427202_with-the-afm-accident-recording-tool-on-the.png" />
      </div>
      <q-banner class="bg-red-1 q-mt-lg border-red-4" rounded>
        <template #avatar><q-icon name="las la-exclamation-triangle" color="red-8 q-mt-xs" size="26px" /></template>
        <div class="fs-12 text-red-8">
          No cuenta con los permisos necesarios para esta acción (<span class="fs-w-600">{{permiso}}</span>).
        </div>
      </q-banner>
    </div>
  </div>
</template>

<script>
  export default {
    name: "permisosError",
    props: {
      permiso: {type: String, default: ""},
    }
  }
</script>