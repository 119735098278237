<template>
  <div>
    <div class="row justify-center items-center q-mt-xs">
      <q-card class="w950" flat>
        <q-card-section>
          <q-banner class="text-green-8 bg-green-1 border-green-4 text-left" rounded>
            <template v-slot:avatar>
              <q-icon name="las la-check" color="green-8" size="24px" class="q-mt-sm"/>
            </template>
            <span class="fs-13">{{mensaje || 'Registro agregado correctamente'}}</span>
          </q-banner>
        </q-card-section>
        <q-separator inset/>
        <q-card-section class="row justify-between items-start">
          <div class="col-12 q-mb-md">
            <slot name="table">
              <q-markup-table flat dense wrap-cells separator="none">
                <slot name="bodyTable" />
              </q-markup-table>
            </slot>
          </div>
          <q-separator class="q-mb-md"/>
          <div class="w100p row text-right">
            <div class="w100p">
              <slot name="groupButton" />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
  export default {
    name: "cardSuccess",
    props:{
      mensaje: String,
    }
  }
</script>