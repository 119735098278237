import axiosUtils from "../Services/Requests/AxiosUtils";
import axios      from "../Services/Requests/AxiosConfig";

export default{
  existePermiso(permiso) {
    return new Promise((resolve, reject) => {
      let params = {params: axiosUtils.getParams(permiso)};
      
      axios.get("permisos/existePermiso", params).then(resp => {
        let data = resp.data;
        if (data.codigo !== 200)
          throw data.mensaje;

        resolve(data.datos);
        
      }).catch(err => axiosUtils.axiosCatch(err, error => reject(error)));
    });
  },
}